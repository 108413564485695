// TODO : transform instruction to a model (see src/models for examples)

/**
 * contain the functions for call the api and retrieve the instructions
 */
export const instructionsCaller = {
  data: function () {
    return {
      /**
       * @type {Object} instructionSortedByIo : the instruction sorted by io
       */
      instructionSortedByIo: {},
      /**
       * @type {Boolean} callProcessIsRunning : is a process currently running ?
       */
      callProcessIsRunning: false,
      /**
       * @type {String} whichProcessRun : the name of the process currently running (get|create|put|buckets|delete|getIO)
       */
      whichProcessRun: '',
      /**
       * @type {Array} allowedProcessType : contain the allowed process type
       */
      allowedProcessType: ['get', 'create', 'put', 'delete', 'buckets', 'getIO', 'createMulti'],
      /**
       * @type {Number}
       */
      defaultLimit: 20,
      /**
       * @type {Number}
       */
      defaultOffset: 0,
      /**
       * for avoid to display a error message when the user change dsp during streaming search loading
       * this value is set to true in this situation, and reset to false during the check in askForErrorMessage
       */
      hasBeenCancelByUser: false
    }
  },
  methods: {
    /**
     * make a UPDATE call with axios to the api
     * @param instruction : {Object} the instruction to update
     */
    updateCall: async function (instruction) {
      let data = instruction
      data = this.addDspToData(data)

      let response = await this.$apiCaller.putInstruction(data, instruction['id'])

      if (this.$apiCaller.isResponseError(response)) {
        this.askForErrorMessage(response)
        return response
      } else {
        return response
      }
    },
    async updateCallMulti (instructions) {
      let response = []
      for (let i in instructions) {
        response.push(await this.updateCall(instructions[i]))
      }

      let success = true
      for (let i in response) {
        if (response[i].status !== 200) {
          success = false
        }
      }

      if (success) {
        let message = 'All instruction have been successfully updated'
        this.askForSuccessMessage(message)
      }
    },
    /**
     * @param instructions {Instruction[]}
     * @param acknowledgments {number[]}
     * @param toDelete {number[]}
     * @param baseline {Baseline}
     * @returns {Promise<_.LoDashFp.T>}
     */
    async postRawCall (instructions, acknowledgments = null, toDelete = [], baseline = null) {
      let error = false
      const result = await this.$apiCaller.batchInstruction(this.dsp, instructions, acknowledgments, toDelete, baseline)

      if (this.$apiCaller.isResponseError(result)) {
        this.askForErrorMessage(result)
      } else {
        let createdId = []
        let deletedId = []

        for (let i in result.data) {
          if ([200, 201].indexOf(result.data[i][1]) === -1) {
            const message = 'Partial error when updating 1 or more of the instruction(s). Please contact an administrator.'
            error = true
            this.$emit('ask-snackbar', message, 'error')
          }

          if (result.data[i][1] === 201) {
            createdId.push(result.data[i][0])
          }

          if (result.data[i][1] === 200) {
            deletedId.push(result.data[i][0])
          }
        }

        if (!error) {
          let message = 'All instruction have been successfully updated'
          this.askForSuccessMessage(message)
        }

        if (createdId.length > 0) {
          const createOnUpdate = createdId.length !== instructions.length
          // refresh after processing the last element
          // a timeOut is setted cause if the new result is fetched too earlier, google store hasn't updated
          // all rows in time and outdated data are displayed
          setTimeout(() => {
            this.streamingFetch()
            this.setProcessRunning(false)
          }, 600)

          let ioId = instructions[0][this.$getIoField(this.dsp)]
          let message = 'New insertion order with the id ' + ioId + ' with ' + instructions.length + ' instruction has been successfully created'

          if (createOnUpdate) {
            message = createdId.length + ' new instruction(s) has been right added to the insertion order ' + ioId
          }
          this.askForSuccessMessage(message)
        }

        if (deletedId.length) {
          let ioId = instructions[0][this.$getIoField(this.dsp)]
          let message = deletedId.length + ' instruction(s) has been successfully deleted for the insertion order with the id ' + ioId
          this.askForSuccessMessage(message)
        }
      }
      return !error
    },
    /**
     * Call the delete by id endpoint for each id in toDeleteIdList and after that, send instructions to create and update to the endpoint instruction_multi,
     * for avoid "not homogenous error" by being sur that the instruction to delete are well deleted before the update / create call
     * @param toUpdateCreateInstructionList {Instruction[]}
     * @param acknowledgments {AcknowledgmentInfo[]}
     * @param toDeleteIdList {number[]}
     * @param baseline {Baseline}
     * @return {Promise<void>}
     */
    async deleteAndPostRaw (toUpdateCreateInstructionList, acknowledgments = null, toDeleteIdList = null, baseline = null) {
      const ackIdOk = this.acknowledgmentToAckArgs(acknowledgments)
      let success = await this.postRawCall(toUpdateCreateInstructionList, ackIdOk, toDeleteIdList, baseline)

      if (!success) {
        console.warn('Error deleteAndPostRaw')
      }
    },
    /**
     * @param acknowledgments {AcknowledgmentInfo[]}
     * @return {number[]}
     */
    acknowledgmentToAckArgs (acknowledgments) {
      return acknowledgments.filter(item => item.is_ok).map(item => item.id)
    },
    /**
     * @param acknowledgments {AcknowledgmentInfo[]}
     * @returns {boolean} if the update a success ?
     */
    async updateAcknowledgments (acknowledgments) {
      const ackIdOk = this.acknowledgmentToAckArgs(acknowledgments)
      const response = await this.$apiCaller.setAcknowledgmentIsOk(ackIdOk, true)
      let success = false
      if (this.$apiCaller.isResponseError(response)) {
        this.askForErrorMessage(response)
      } else {
        success = true
        console.warn('acknowledgment right updated')
      }
      return success
    },
    /**
     * make a call for DELETE a instruction
     * @param instruction : {Object} the instruction to delete
     */
    deleteCall: function (instruction) {
      return this.__deleteCallProcess(instruction.id)
    },
    /**
     * make a call for DELETE a instruction
     * @param instructionId : {Number} the id of the instruction to delete
     */
    deleteCallWithId: function (instructionId) {
      return this.__deleteCallProcess(instructionId)
    },
    /**
     * PRIVATE
     *
     * @param id : {Number} the id to delete
     */
    __deleteCallProcess: async function (id) {
      let response = await this.$apiCaller.deleteInstruction({ dsp: this.dsp }, id)

      if (this.$apiCaller.isResponseError(response)) {
        this.askForErrorMessage(response)
      } else {
        let message = `The instruction ${id} has been right deleted.`
        this.askForSuccessMessage(message)
      }
    },
    createInstructionCall: async function (instruction) {
      let success = true
      let data = instruction
      data = this.addDspToData(data)
      this.setProcessRunning(true, 'createMulti')

      let response = await this.$apiCaller.postNewInstruction(data)

      if (this.$apiCaller.isResponseError(response)) {
        this.askForErrorMessage(response)
        this.setProcessRunning(false)
        success = false
      } else {
      }

      return success
    },
    askForErrorMessage (error) {
      let errorMessage
      let status
      let message = ''
      // to nothing if the error is the cancel is due to the user
      if (this.hasBeenCancelByUser) {
        this.hasBeenCancelByUser = false
        return
      }

      message = 'An error occurred during the process. Please contact an administrator.'

      if (error.response !== undefined && error.response.data !== undefined) {
        errorMessage = error.response.data.errors !== undefined ? error.response.data.errors : 'NC'
        status = error.response.data.status !== undefined ? error.response.data.status : 'NC'

        if (status === 401) {
          this.$emit('ask-relog')
          return
        }

        if (Array.isArray(errorMessage)) {
          errorMessage = errorMessage.join(' ,')
        } else if (typeof errorMessage === 'object') {
          errorMessage = JSON.stringify(errorMessage)
        }

        message += ' Server Status : ' + status + '. Message : ' + errorMessage
      } else {
        message += error
      }

      this.$emit('ask-snackbar', message, 'error')
    },
    askForSuccessMessage: function (message) {
      this.$emit('ask-snackbar', message)
    },
    /**
     * make a call for a BUCKETS update
     * @param bucketsImploded : {String} the list of id to apply the bucketsCall.
     * Must be like that : 11111,22222,33333
     * @param action : {Object} contain the action to apply to these buckets
     * Must contain the field to modify in key, and the value in value
     */
    bucketsCall: async function (bucketsImploded, action) {
      let data = action
      data['buckets_id'] = bucketsImploded
      data = this.addDspToData(data)

      this.setProcessRunning(true, 'buckets')
      let response = await this.$apiCaller.bucketsInstructions(data)

      if (this.$apiCaller.isResponseError(response)) {
        this.askForErrorMessage(response)
        this.setProcessRunning(false)
      } else {
        // refresh after processing
        // a timeOut is setted cause if the new result is fetched too earlier, google store hasn't updated
        // all rows in time and outdated data are displayed
        setTimeout(() => {
          this.queryStringProcess()
          this.streamingFetch()
          this.setProcessRunning(false)
        }, 350)

        let message = 'Buckets edit success !'
        this.askForSuccessMessage(message)
      }
    },
    // get the value in the input with the id "searchedElementId" and add it to the url call to the api
    createApiCallStringWithSearchedElement: function (searchedElement, key, url) {
      if (searchedElement !== '' && searchedElement != null && typeof searchedElement !== 'undefined') {
        url += key + '=' + searchedElement.trim()
        url += '&'
      }

      return url
    },
    /**
     * add dsp to the data object
     * @param data : {Object} data
     */
    addDspToData (data) {
      data['dsp'] = this.dsp
      return data
    },
    /**
     * call the 10 first instructions for a quick display
     * and call the rest of the instructions after
     */
    async streamingFetch () {
      this.$store.commit('setStreamingProcessInProgress', true)
      const options = this.$store.getters.getOptionsInstructions
      await this.fetchAllIO(options.itemsPerPage, (options.page - 1) * options.itemsPerPage, false)
      this.$store.commit('setStreamingProcessInProgress', false)
    },
    /**
     * used for setted the status of the call
     * @param isRunning : {Boolean}
     * @param processType : {String} must be create|put|get|buckets|delete|getIO|createMulti
     */
    setProcessRunning (isRunning, processType = '') {
      if (isRunning === true && processType === '') {
        throw new Error('processType must be setted when isRunning is true')
      }

      if (isRunning === true && this.allowedProcessType.indexOf(processType) === -1) {
        throw new Error('processType must be one of these values : create|put|get|buckets|delete|getIO|createMulti')
      }
      this.callProcessIsRunning = isRunning
      this.whichProcessRun = processType
    },
    /**
     * call the api for getting data
     * @param limit : {Number} limit
     * @param offset : {Number} offset
     * @param append : {Boolean} if we add the data to the instructions rather than replace it, very usefull for streamingFetch
     */
    fetchAllIO: async function (limit = null, offset = null, append = false) {
      limit = !limit || !this.$commonUtils.isNumeric(limit) ? this.defaultLimit : limit
      offset = !offset || !this.$commonUtils.isNumeric(offset) ? this.defaultOffset : offset

      let currentSearch = this.getCurrentSearchData()
      let data = { ...{ dsp: this.dsp, orderByIO: true, limit: limit, offset: offset }, ...currentSearch }

      if (append === false) {
        this.instructionSortedByIo = {}
      }

      let response = null
      response = await this.$apiCaller.getInstructions(data)

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('ERROR WHEN STREAMING FETCH')
        this.askForErrorMessage(response)
      } else {
        if (append === false) {
          this.instructionSortedByIo = response.data
        } else {
          // A TypeError can be thrown when the user change dsp too quickly
          try {
            this.updateInstructionsList(response)
            this.sortInstructionsByIsActive()
          } catch (TypeError) {
            console.warn(`TypeError when streaming fetch process : ${TypeError.message}. Refresh reset the process...`)
            this.$store.commit('setStreamingProcessInProgress', false)
            this.streamingFetch()
          }
        }
      }
    },
    sortInstructionsByIsActive: function () {
      Object.keys(this.instructionSortedByIo).forEach((value) => {
        this.instructionSortedByIo[value].sort(this.$sortByIsActive)
      })
    },
    /**
     * divide the instructions in response in the right key (optimize id) of the instruction list
     * @param response
     */
    updateInstructionsList: function (response) {
      for (let key in response.data) {
        if (this.instructionSortedByIo.hasOwnProperty(key) === false) {
          this.$set(this.instructionSortedByIo, key, [])
        }
        for (let i in response.data[key]) {
          this.instructionSortedByIo[key].push(response.data[key][i])
        }
      }
    },
    getCurrentSearchData () {
      let currentSearch = this.$store.getters.getCurrentSearch
      let cleanedCurrentSearch = {}
      for (let i in currentSearch) {
        if (this.$isNotNullOrUndefined(currentSearch[i]) && currentSearch[i] !== '') {
          cleanedCurrentSearch[i] = currentSearch[i]
        }
      }

      return cleanedCurrentSearch
    }
  }
}
