<template>
    <div>
      <Table
        :data="instructionSortedByIo"
        :dsp="dsp"
        v-on:delete-element="deleteCall"
        v-on:delete-and-post-raw="deleteAndPostRaw"
        v-on:buckets-update="bucketsCall"
        v-on:search-complex="searchComplex"
        v-on:query-string="queryStringProcess"
        v-on:delete-id="deleteCallWithId"
        v-on:refresh-data="streamingFetch"
        v-on:ask-snackbar="tableAskForSnackbar"
        v-if="!callProcessIsRunning"
      >
      </Table>
      <WaitBar
      :message="messageCallProcess"
      v-if="callProcessIsRunning"
      >
      </WaitBar>
    </div>
</template>

<script>
import { instructionsCaller } from '../mixins/instructionsCaller'
import store from '../../store/index.js'
import { overlay } from '../../store/modules/overlay.js'
import { mapActions, mapGetters } from 'vuex'
import { usersMixin } from '../mixins/usersMixin'
import { baseViewMixin } from '@/mixins/baseViewMixin'

// load the store module dynamically only when needed
if (!store.state.overlay) store.registerModule('overlay', overlay)

export default {
  name: 'Table_vue',
  props: ['dsp', 'openDialog'],
  mixins: [instructionsCaller, usersMixin, baseViewMixin],
  data: function () {
    return {
      searched: {},
      messageCallProcess: ''
    }
  },
  components: {
    Table: () => import('../components/TableComponents/Table'),
    WaitBar: () => import('../components/Common/WaitBar')
  },
  methods: {
    ...mapActions(['openFormWithDataForm']),
    searchComplex (searchObject) {
      for (let key in searchObject) {
        if (searchObject[key] === 1) {
          this.currentSearch[key] = ''
          continue
        }
        this.currentSearch[key] = searchObject[key] === 0 ? 'false' : 'true'
      }
      this.streamingFetch()
      this.queryStringProcess()
    },
    /**
     * write in search bar the current search and form status and values
     * do nothing until all values from request hasn't been recovered
     */
    queryStringProcess () {
      const currentSearch = {}

      for (let key in this.currentSearch) {
        if (this.currentSearch[key]) {
          currentSearch[`q_${key}`] = this.currentSearch[key]
        }
      }

      const path = this.$router.resolve({
        params: this.$route.params,
        query: {
          ...currentSearch,
          ...this.$route.query
        }
      })

      if (path.href !== this.$route.fullPath) {
        this.$router.replace(path.location)
      }
    },
    /**
     * look in $route.query for potential search
     */
    getCurrentSearchInQuery () {
      let valueToCheck = this.$store.getters.getCurrentSearchKeys

      for (let key in valueToCheck) {
        let value = valueToCheck[key]
        let queryKey = 'q_' + value

        this.currentSearch[value] = this.$route.query[queryKey]

        if (this.currentSearch[value] !== undefined) {
          this.$store.commit('setComplexSearchFromQuery', true)
        }
      }
      this.$store.commit('setValuesFromRequestSearchRecovered', true)
    },
    async checkDataForm () {
      if (this.$route.query.data_form !== undefined) {
        let dataForm = JSON.parse(this.$route.query.data_form)
        this.openFormWithDataForm(dataForm)
      }
    },
    tableAskForSnackbar (message, status = 'success') {
      this.$emit('ask-snackbar', message, status)
    }
  },
  computed: {
    currentSearch: {
      get () {
        return this.$store.getters.getCurrentSearch
      },
      set (currentSearch) {
        this.$store.commit('setCurrentSearch', currentSearch)
      }
    }
  },
  created: function () {
    this.getCurrentSearchInQuery()
  },
  mounted: async function () {
    this.onMounted()
    const result = await this.$surcoucheConfig.refreshConfig()

    if (!result) {
      this.$emit('error-surcouche-config')
    } else {
      this.streamingFetch().then(() => {
        this.checkDataForm()
      })
    }
  },
  watch: {
    '$route.params.dsp': function (dsp) {
      this.$store.commit('setCurrentSearch', {})
      this.instructionList = []
      // we do not care about the fact than a streamingProcess is already in progress if the dsp is changing
      this.$store.commit('setStreamingProcessInProgress', false)
      this.streamingFetch()
    },
    'callProcessIsRunning': function (callProcessIsRunning) {
      if (callProcessIsRunning) {
        this.messageCallProcess = this.whichProcessRun === 'create' || this.whichProcessRun === 'createMulti' ? 'Create new element process...' : 'Buckets edit is processing...'
      }
    }
  }
}
</script>

<style>
  #search-input-box {
    padding: 2em;
  }
  #search-input-box span {
      display: none !important
  }

  .label-table-line {
    font-weight: 500;
  }

  @media only screen
  and (max-width: 1823px) {
    .label-table-line {
      font-size: 8px !important;
    }
  }

    /* Large screens ----------- */
  @media only screen
  and (min-width : 1824px) {
  /* Styles */
    .std-table table.v-table thead td:not(:nth-child(1)),
    .std-table table.v-table tbody td:not(:nth-child(1)),
    .std-table table.v-table thead th:not(:nth-child(1)),
    .std-table table.v-table tbody th:not(:nth-child(1)),
    .std-table table.v-table thead td:first-child,
    .std-table table.v-table tbody td:first-child,
    .std-table table.v-table thead th:first-child,
    .std-table table.v-table tbody th:first-child {
      padding: 1.2em;
    }

    .std-table table.v-table thead th {
      font-size: 13px;
    }

    .std-table table.v-table tbody td {
      font-size: 10px !important;
    }

    .label-table-line {
      font-size: 10px !important;
    }

    th.dsp-header-table.column.sortable.asc {
      width: 32rem;
    }

    .v-chip.mini-chip .v-chip__content {
        font-size: 10px !important;
    }
  }

</style>
